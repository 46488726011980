import React, { useState, useEffect } from 'react';

import iconSeguridad001 from '../../../assets/images/sliders/icon-seguridad001.webp';
import iconSeguridad002 from '../../../assets/images/sliders/icon-seguridad002.webp';
import iconSeguridad003 from '../../../assets/images/sliders/icon-seguridad003.webp';
import iconSeguridad004 from '../../../assets/images/sliders/icon-seguridad004.webp';
import iconSeguridad005 from '../../../assets/images/sliders/icon-seguridad005.webp';
import iconSeguridad006 from '../../../assets/images/sliders/icon-seguridad006.webp';
import iconSeguridad007 from '../../../assets/images/sliders/icon-seguridad007.webp';
import iconSeguridad008 from '../../../assets/images/sliders/icon-seguridad008.webp';
import iconSeguridad009 from '../../../assets/images/sliders/icon-seguridad009.webp';
import iconSeguridad010 from '../../../assets/images/sliders/icon-seguridad010.webp';

const SliderItems = () => {
    const items1 = [
        { src: iconSeguridad001, label: 'JavaScript' },
        { src: iconSeguridad002, label: 'PHP' },
        { src: iconSeguridad003, label: 'Python' },
        { src: iconSeguridad004, label: 'Git' },
        { src: iconSeguridad005, label: 'Visual Code' }
    ];

    const items2 = [
        { src: iconSeguridad006, label: 'Visual Studio' },
        { src: iconSeguridad007, label: 'Node' },
        { src: iconSeguridad008, label: 'Java' },
        { src: iconSeguridad009, label: 'TypeScript' },
        { src: iconSeguridad010, label: 'WordPress' }
    ];

    const [currentItems, setCurrentItems] = useState(items1);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [visibleItemsCount, setVisibleItemsCount] = useState(1);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 375) {
                setVisibleItemsCount(1);
            } else if (window.innerWidth <= 768) {
                setVisibleItemsCount(3);
            } else {
                setVisibleItemsCount(5);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentItems(prev => (prev === items1 ? items2 : items1));
            setCurrentIndex(0);
        }, 5000);

        return () => clearInterval(interval);
    }, []);



    const getVisibleItems = () => {
        const endIndex = currentIndex + visibleItemsCount;
        if (endIndex > currentItems.length) {
            return [...currentItems.slice(currentIndex), ...currentItems.slice(0, endIndex - currentItems.length)];
        }
        return currentItems.slice(currentIndex, endIndex);
    };

    return (
        <div className="relative w-full overflow-hidden">
            <div className="flex">
                {getVisibleItems().map((item, index) => (
                    <div key={index} className="text-center p-2" style={{ width: `${100 / visibleItemsCount}%` }}>
                        <img className="mx-auto" src={item.src} alt={`icon-${index}`} />
                        <p className="text-white">{item.label}</p>
                    </div>
                ))}
            </div>            
        </div>
    );
};

export default SliderItems;
