import React from 'react';
import UIButton from '../../atoms/UIButton';
import Card from './Card';
import { useTranslation } from "react-i18next";

function CardNewsLetter() {
    const { t } = useTranslation();
    return (
        <Card 
            className={'p-4'}
            children={
                <>
                    <p className="text-white text-lg mb-4">
                        <strong>
                            {t('CardNewsTitle')}
                        </strong>
                    </p>
                    <p className="text-white">
                        {t('CardNewsSubTitle')}
                    </p>
                    <input className="text-center p-2 mt-4 mb-4 w-full max-w-sm mx-auto" type="email" placeholder="Tu correo electrónico" />
                    <div className="flex justify-center">
                        <div className="mt-3">
                            <UIButton
                                primary
                                children={<>{t('CardNewsButton')}</>}
                            />
                        </div>
                    </div>                
                
                </>
            }
        />
    );
}

export default CardNewsLetter;
