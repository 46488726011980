import React, { useState, useEffect } from "react";
import Footer from "./components/organisms/Footer";
import Rutas from "./router/Rutas";
import './i18n/i18n';
import UIButton from "./components/atoms/UIButton";
import buttonUp from "./assets/images/demo-explorer/images/icons/icon_up.webp";

function App() {

  const [showButton, setShowButton] = useState(false);


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 800) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };


    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <React.StrictMode>
      {showButton && (
        <UIButton
        className={'w-12 md:w-20'}
        action={scrollToTop}
        children={
          <img
            className={`w-12 h-auto fixed right-2 z-30 top-[60%] transition-opacity duration-500 ease-in-out 
              ${showButton ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4 pointer-events-none'}`}
            src={buttonUp}
            alt="Scroll to top"
          />
        }
      />
      )}
      <div className="bg-home-pattern bg-fixed bg-cover h-full z-10">
        <Rutas />
        <Footer />
      </div>
    </React.StrictMode>
  );
}

export default App;
