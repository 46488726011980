import React, { useState } from "react";
import Header from "../organisms/Header";
import Carousel from "../molecules/carousel/Carousel";
import slide_001 from "../../assets/images/websites/websites_slide_001.webp";
import slide_002 from "../../assets/images/websites/websites_slide_002.webp";
import slide_003 from "../../assets/images/websites/websites_slide_003.webp";
import robot_001 from "../../assets/images/robots/robot_001.webp";
import robot_003 from "../../assets/images/robots/robot_003.webp";
import img_001 from "../../assets/images/icons/icon_001_1x.webp";
import img_002 from "../../assets/images/icons/icon_002_1x.webp";
import img_003 from "../../assets/images/icons/icon_003_1x.webp";
import img_004 from "../../assets/images/icons/icon_004_1x.webp";
import UIButton from "../atoms/UIButton";
import Card from "../molecules/cards/Card";
import carrito from "../../assets/images/icons/icon_shopping_cart.webp";
import Modal from "../molecules/modals/Modal";
import { useTranslation } from "react-i18next";
import ClientDescription from "../molecules/clientDescription/ClientDescription";
import pizzaza_001 from "../../assets/images/pizzaza/pizzaza_logo_v.webp";
import pizzaza_002 from "../../assets/images/pizzaza/pizzaza_web.webp";
import SocialContacts from "../organisms/SocialContacts";
import CarouselBlog from "../molecules/carousel/CarouselBlog";
import ContactAndCartModal from "../molecules/modals/ContactAndCartModal";

const Webdesign = () => {
    const { t } = useTranslation();

    const [selectedPlan, setSelectedPlan] = useState(null); // Estado para el carrito
    const openModalWithPlan = (plan) => {
        setSelectedPlan(plan); // Actualiza el plan seleccionado
        setIsModalOpen(true); // Abre el modal
    };

    const slides = [
        { image: slide_001, text: <span>Tu sitio web en <span className="text-yellow-500">cualquier </span> dispositivo</span> },
        { image: slide_002, text: <span><span className="text-yellow-500">Landing pages</span> para tus campañas de marketing</span> },
        { image: slide_003, text: <span>Medición de <span className="text-yellow-500">tráfico</span> con analytics y lighthouse</span> },
    ];

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    // Configuración de las tarjetas
    const cardData = [
        {
            name: "Landing Page",
            description: "Una página de aterrizaje optimizada y analizada con Google Analytics que tus usuarios visitan cuando hacen click en alguna publicidad externa.",
            price: 1000000,
            details: "1 Página con formulario de contacto.",
            descriptionPlan: "Una (1) página de aterrizaje con banners, galerías, inclusión de videos, links externos y formulario de contacto.",
            extras: [                                
                { id: "logo_design", name: "Diseño de Logo Profesional.", price: 300000 },
                { id: "branding_manual", name: "Manual de Imagen Corporativa.", price: 500000 },
                { id: "brochure", name: "Brochure o menú: (1) Un Archivo .pdf para impresión y (1) en resolución para pantalla de tus productos o servicios.", price: 400000 },
            ],
        },
        {
            name: "Sitios Web Word Press",
            description: "Páginas administrables (CMS), stios robustos con gran cantidad de contenido, administración de roles y perfiles de usuarios, plugins y widgets para funcionalidaes extra para tu sitio.",
            price: 1200000,
            details: "4 Páginas con navegación interna y vínculos externos",
            descriptionPlan: "Instalación y configuración de Word Press, implementación de tema de diseño y diagramación de contenidos como textos e imágenes, Cuatro (4) páginas como Inicio, Sobre Nosotros, Servicios, Productos y Contacto",
            extras: [
                { id: "logo_design", name: "Diseño de Logo Profesional", price: 300000 },
                { id: "branding_manual", name: "Manual de Imagen Corporativa.", price: 500000 },
                { id: "brochure", name: "Brochure o menú: (1) Un Archivo .pdf para impresión y (1) en resolución para pantalla de tus productos o servicios.", price: 400000 },
            ],
        },
        {
            name: "Sitios Web React y Angular",
            description: "Funcionalidades únicas para hacer la experiencia que tu cliente está esperando, comunicación con servicios externos.",
            price: 1200000,
            details: "4 Páginas con navegación interna y vínculos externos.",
            descriptionPlan: "(1) Un sitio web con (4) cuatro páginas como Inicio, Sobre Nosotros, Servicios, Productos y Contacto.",
            extras: [
                { id: "logo_design", name: "Diseño de Logo Profesional", price: 300000 },
                { id: "branding_manual", name: "Manual de Imagen Corporativa.", price: 500000 },
                { id: "brochure", name: "Brochure o menú: (1) Un Archivo .pdf para impresión y (1) en resolución para pantalla de tus productos o servicios.", price: 400000 },
            ],
        },
    ];
    

    return (
        <React.Fragment>
            <Header />
            <Modal
                isOpen={isModalOpen}
                onClose={closeModal}
                className="!bg-[#091433] border border-yellow-500 p-5"
                children={
                    <ContactAndCartModal plan={selectedPlan} />
                }
            />
            <Carousel
                slides={slides}
            />
            <div className="flex flex-col lg:flex-row w-10/12 xl:max-w-[1440px] m-auto items-center py-20">
                <div className="w-2/12 hidden xl:block">
                    <img src={robot_001} />
                </div>
                <div className="flex flex-col w-10/12 m-auto">
                    <div className="w-full flex flex-col lg:flex-row text-center lg:text-left justify-between gap-4 py-10">
                        <p className="text-yellow-500 font-bold text-4xl">
                            Sitios web adaptables
                        </p>
                        <UIButton
                            primary
                            children={'Descarga nuestro brochure'}
                            url={'https://enlacewebdesign.com/portafolio/brochure_enlace-web-design-es-2024.pdf'}
                        />
                    </div> 
                    <div className="w-full flex flex-col lg:flex-row gap-5">
                        {/* Renderizar las tarjetas dinámicamente */}
                        {cardData.map((card, index) => (
                            <Card
                                key={index}
                                onClick={() => openModalWithPlan(card)}
                                className={
                                    "p-4 w-full lg:w-4/12 flex flex-col justify-between items-stretch border border-yellow-500 rounded-md hover:shadow-[0_0_15px_5px_#00ffff52] hover:scale-105 hover:bg-[#487cff80] hover:text-black transition-all duration-300 ease-in-out"
                                }
                                children={
                                    <div className="h-full flex flex-col items-center justify-between gap-4 p-2 text-center min-h-[350px]">
                                        <p className="text-[#FAC50A] font-bold text-2xl">{card.name}</p>
                                        <p className="text-white font-normal text-base">{card.description}</p>
                                        <div className="flex gap-5">
                                            <p className="text-[#FAC50A] font-bold text-2xl">
                                                Desde {card.price.toLocaleString("es-CO")} COP
                                            </p>
                                            <UIButton
                                                children={<img className="w-[24px]" src={carrito} alt="Carrito" />}
                                            />
                                        </div>
                                        <p className="text-white font-normal text-base">{card.details}</p>
                                    </div>
                                }
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-center m-auto gap-5 w-full lg:w-10/12 xl:max-w-[1440px] py-10 px-5 lg:px-40">
                <p className="text-yellow-500 font-bold text-4xl text-center">
                    ¿Cómo trabajamos?
                </p>
                <p className="text-white font-normal text-2xl text-center w-full m-auto">
                    Diseñamos tu sitio web basados en tu marca y valores corporativos para generar identidad corporativa que tu cliente recuerde y tenga la mejor experiencia
                </p>
            </div>
            <div className="py-10 xl:max-w-[1440px] m-auto">
                <p className="text-yellow-500 font-bold text-4xl text-center py-10">
                    Etapas de diseño:
                </p>
                <div className="grid grid-flow-row grid-cols-2 lg:grid-cols-4 w-10/12 m-auto py-10 gap-4">
                    <div className="flex flex-col gap-4 items-center">
                        <img className="w-[60%] h-auto" src={img_001} />
                        <p className="text-white text-2xl font-bold text-center">
                            Diseño de prototipos
                        </p>
                    </div>
                    <div className="flex flex-col gap-4 items-center">
                        <img className="w-[60%] h-auto" src={img_002} />
                        <p className="text-white text-2xl font-bold text-center">
                            Desarrollo del proyecto
                        </p>
                    </div>
                    <div className="flex flex-col gap-4 items-center">
                        <img className="w-[60%] h-auto" src={img_003} />
                        <p className="text-white text-2xl font-bold text-center">
                            Pruebas y calidad
                        </p>
                    </div>
                    <div className="flex flex-col gap-4 items-center">
                        <img className="w-[60%] h-auto" src={img_004} />
                        <p className="text-white text-2xl font-bold text-center">
                            Entrega del proyecto
                        </p>
                    </div>
                </div>
            </div>
            <div className="py-10 px-5">
                <div className="w-full lg:w-10/12 xl:max-w-[1024px] lg:h-[250px] m-auto flex flex-col lg:flex-row gap-4 p-10 items-center bg-gradient-cards from-brand-gray via-brand-gray to-brand-dark-gray border border-yellow-500 rounded-xl">
                    <div className="text-center w-full lg:w-4/12">
                        <p className="text-yellow-500 font-bold text-2xl">
                            Diseñamos tu imagen corporativa
                        </p>
                        <p className="text-white  font-normal text-xl">
                            Te ayudamos a crear una imagen visual llamativa que integre el concepto y la identidad de tu empresa
                        </p>
                    </div>
                    <div className="relative w-full lg:w-4/12 h-full">
                        <img className="lg:absolute lg:left-1/2 lg:-translate-x-1/2 lg:bottom-[-80px] w-[80%] m-auto lg:w-[500px] max-w-none" src={robot_003} />
                    </div>
                    <div className="text-center w-full lg:w-4/12">
                        <p className="text-yellow-500 font-bold text-2xl">
                            Campañas de marketing
                        </p>
                        <p className="text-white font-normal text-xl">
                            Te ayudamos a crear campañas de community manager para tus redes sociales
                        </p>
                    </div>
                </div>
            </div>
            <div className="py-10">
                <p className="text-white font-normal text-2xl text-center w-full lg:w-[50%] m-auto">
                    Conoce demos de nuestros proyectos
                </p>
                <p className="text-yellow-500 font-bold text-4xl text-center">
                    Sitios web para tu negocio
                </p>
            </div>
            <div className="flex w-10/12 xl:max-w-[1440px] m-auto">
                <ClientDescription
                    title='Sitio Web "Pizzaza"'
                    description1="Sitio web para compras online y contacto directo con el cliente, vínculo a WhatsApp y envío a correo electrónico"
                    description2="Comercia tus productos y servicios en una interfaz para dispositivos móviles y de escritorio"
                    buttonText="Conoce el sitio web"
                    buttonUrl="https://tupizzaza.com"
                    logoSrc={pizzaza_001}
                    siteImageSrc={pizzaza_002}
                    badges={[
                        { text: "Sitio Web", variant: "primary" },
                        { text: "Landing page", variant: "LandingPage" },
                        { text: "Responsive", variant: "Responsive" },
                    ]}
                />
            </div>
            <CarouselBlog />
            <SocialContacts />
        </React.Fragment>
    )
}
export default Webdesign;
