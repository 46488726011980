import React from "react";
import { useTranslation } from "react-i18next";
import brand from "../../assets/images/brand/brand_positive.webp";
const Footer = () => {

    const { t } = useTranslation();

    return (
        <footer className="bg-black py-5">
            <div className="px-5 lg:px-10">
                <div className="flex flex-col justify-between lg:flex-row gap-8">
                    <div className="w-full flex flex-col items-center lg:items-start lg:w-4/12 max-w-[450px]">
                        <img className="w-[200px] my-5" src={brand}></img>
                        <h5 className="text-white text-center md:text-left">
                            {t('FooterTitle')}                            
                        </h5>
                    </div>
                    <div className="w-full lg:w-4/12 text-center">
                        <h4 className="text-white mb-4">{t('FooterSubTitle')}</h4>
                        <ul className="text-white">
                            <li>
                                <a className="text-white hover:text-yellow-500 text-base" href="/home">{t('HeaderHome')}</a>
                            </li> 
                            <li>
                                <a className="text-white hover:text-yellow-500 text-base" href="#aboutUs">{t('HeaderAbout')}</a>
                            </li> 
                            <li>
                                <a className="text-white hover:text-yellow-500 text-base" href="/web-design">{t('HeaderSites')}</a>
                            </li> 
                            <li>
                                <a className="text-white hover:text-yellow-500 text-base" href="https://enlacewebdesign.com/ecommerce/" target="_blank">{t('HeaderEcommerce')}</a>
                            </li> 
                            <li>
                                <a className="text-white hover:text-yellow-500 text-base" href="/apps">{t('HeaderApps')}</a>
                            </li> 
                            <li>
                                <a className="text-white hover:text-yellow-500 text-base" href="/store">{t('HeaderStore')}</a>
                            </li> 
                            <li>
                                <a className="text-white hover:text-yellow-500 text-base" href="/blog">{t('HeaderBlog')}</a>
                            </li> 
                            <li>
                                <a className="text-white hover:text-yellow-500 text-base" href="/contact">{t('HeaderContact')}</a>
                            </li>            
                        </ul>
                    </div>
                </div>
                <hr className="my-8 border-white" />
                <div className="text-center">
                    <h5>
                        <a className="text-white hover:text-yellow-500" href="/">
                            {t('FooterTerms')}
                        </a>
                    </h5>
                    <h5 className="text-white">
                        {t('FooterReserved')}
                    </h5>
                </div>
            </div>
        </footer>
    )
}
export default Footer;