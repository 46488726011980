import React from 'react';
import imgBuild from "../../assets/images/social/build_react.webp";
import CardNewsLetter from '../molecules/cards/CardNewsletter';
import CardSocial from '../molecules/cards/CardSocial';

function SocialContacts() {
    return (
        <div className="w-full min-h-[800px] flex flex-col-reverse xl:justify-center md:flex-row relative bg-social-contacts bg-contain bg-bottom bg-no-repeat  py-6 md:px-32 rounded-lg ">
            <div className='w-10/12 md:w-7/12 xl:max-w-[700px] m-auto xl:m-0'>
                <img src={imgBuild} />
            </div>
            <div className='w-10/12 md:w-5/12 xl:max-w-[700px] m-auto xl:m-0 flex flex-col justify-center items-center '>
                <div className='mb-8 xl:text-center'>
                    <CardNewsLetter />
                </div>
                <div className='w-full xl:w-10/12 flex justify-center items-center'>
                    <CardSocial />
                </div>
            </div>
        </div>
    );
}

export default SocialContacts;
