import React, { useEffect } from "react";

const Modal = ({ isOpen, onClose, children, className }) => {
    useEffect(() => {
        if (isOpen) {
            // Deshabilitar scroll del fondo
            document.body.style.overflow = "hidden";
        } else {
            // Restaurar scroll del fondo
            document.body.style.overflow = "auto";
        }

        // Limpiar estilo al desmontar el componente
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80"
            onClick={onClose} // Cerrar modal al hacer clic en el fondo
        >
            <div
                className={`bg-white rounded-[50px] shadow-lg w-11/12 md:w-2/3 lg:w-2/3 max-w-[1100px] max-h-[90vh] overflow-y-auto relative ${className}`}
                onClick={(e) => e.stopPropagation()} // Evita que el clic cierre el modal
            >
                {/* Header */}
                <div className="absolute top-5 right-5 flex justify-end items-center p-4">
                    <button
                        onClick={onClose}
                        className="text-gray-50 hover:text-red-500"
                        aria-label="Close modal"
                    >
                        &#10005;
                    </button>
                </div>
                {/* Content */}
                <div className="p-4 ">{children}</div>
                {/* Footer */}
                <div className="flex justify-end p-4">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-gray-300 hover:bg-gray-400 text-gray-800 rounded-md"
                    >
                        Cerrar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
