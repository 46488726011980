import React from "react";
import Header from "../organisms/Header";
import Carousel from "../molecules/carousel/Carousel";
import slide_001 from "../../assets/images/apps/apps_001.webp";
import slide_002 from "../../assets/images/apps/apps_002.webp";
import slide_003 from "../../assets/images/apps/apps_003.webp";
import UIButton from "../atoms/UIButton";
import icon_005 from "../../assets/images/icons/icon_005_1x.webp";
import icon_006 from "../../assets/images/icons/icon_006_1x.webp";
import icon_007 from "../../assets/images/icons/icon_007_1x.webp";
import icon_008 from "../../assets/images/icons/icon_008_1x.webp";
import ClientDescription from "../molecules/clientDescription/ClientDescription";
import demo_explorer_001 from "../../assets/images/demo-explorer/images/brand/brand_positive.webp";
import demo_explorer_002 from "../../assets/images/demo-explorer/images/banner-discover/img_mobile.webp";
import SocialContacts from "../organisms/SocialContacts";
import CarouselBlog from "../molecules/carousel/CarouselBlog";

const Apps =()=>{

    const slides = [
        { image: slide_001, text: "Servicios para tus clientes en su dispositivo móvil" },
        { image: slide_002, text: "Interacción directa y la mejor experiencia para tu cliente" },
        { image: slide_003, text: "Interfaces únicas con diseños personalizados" },
    ];

    return (
        <React.Fragment>
            <Header />
            <Carousel
                slides={slides}
            />
            <div className="w-full">
                <div className="w-10/12 m-auto flex flex-col gap-5 items-center">
                    <div className="py-10 flex flex-col items-center gap-5 text-center">
                        <p className="text-white font-normal text-xl">
                            Conoce demos de nuestros proyectos
                        </p>
                        <p className="text-yellow-500 font-bold text-4xl ">
                            Ideas de aplicaciones para tu empresa
                        </p>
                        <UIButton
                            className={'lg:w-[400px]'}
                            primary
                            children={'Descarga nuestro brochure'}
                            url={'https://enlacewebdesign.com/portafolio/brochure_enlace-web-design-es-2024.pdf'}
                        />
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-20 lg:p-20  text-center lg:text-left">
                        <div className="flex flex-col lg:flex-row gap-5 items-center">
                            <div className="w-4/12">
                                <img src={icon_005}></img>
                            </div>
                            <div className="w-full lg:w-8/12 flex flex-col">
                                <p className="text-white font-bold text-xl lg:text-4xl">
                                    Domicilios
                                </p>
                                <p className="text-white font-normal text-base lg:text-xl">
                                    Opciones organizadas por categorías para vender tus productos o servicios, notificaciones  en tiempo real y historial de tus transacciones.
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col lg:flex-row gap-5 items-center">
                            <div className="w-4/12">
                                <img src={icon_007}></img>
                            </div>
                            <div className="w-full lg:w-8/12 flex flex-col">
                                <p className="text-white font-bold text-xl lg:text-4xl">
                                    Geolocalización
                                </p>
                                <p className="text-white font-normal text-base lg:text-xl">
                                    Seguimiento por medio de mapas y gps de tus pedidos o recorrido de tus usuarios o productos en tiempo real para transporte.
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col lg:flex-row gap-5 items-center">
                            <div className="w-4/12">
                                <img src={icon_006}></img>
                            </div>
                            <div className="w-full lg:w-8/12 flex flex-col">
                                <p className="text-white font-bold text-xl lg:text-4xl">
                                    Video En línea
                                </p>
                                <p className="text-white font-normal text-base lg:text-xl">
                                    Interfáz para video llamadas en vivo, gestor de agendamiento y creación de salas de chat para múltiples usuarios.
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col lg:flex-row gap-5 items-center">
                            <div className="w-4/12">
                                <img src={icon_008}></img>
                            </div>
                            <div className="w-full lg:w-8/12 flex flex-col">
                                <p className="text-white font-bold text-xl lg:text-4xl">
                                    Ecommerce
                                </p>
                                <p className="text-white font-normal text-base lg:text-xl">
                                    Venta de productos y servicios a través de pasarelas de pago externas, categorías para organizar y administrar tus productos o servicios.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full text-center">
                        <p className="text-white font-normal text-xl">
                            Conoce demos de nuestros proyectos
                        </p>
                        <p className="text-yellow-500 font-bold text-4xl">
                            Aplicaciones que te pueden interesar
                        </p>
                    </div>
                    <ClientDescription
                        title='Demo explorer'
                        description1="Descubre una forma rápida de visualizar tus proyectos de finca raíz y estructuras en primera persona"
                        description2=""
                        buttonText="Descarga tu demo"
                        buttonUrl="https://enlacewebdesign.com/demo-explorer/index.html"
                        logoSrc={demo_explorer_001}
                        siteImageSrc={demo_explorer_002}
                        badges={[
                            { text: "Sitio Web", variant: "primary" },
                            { text: "Landing page", variant: "LandingPage" },
                            { text: "Responsive", variant: "Responsive" },
                        ]}
                    />
                </div>
                <CarouselBlog />
                <SocialContacts />
            </div>
        </React.Fragment>
    )
}
export default Apps;
