import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../organisms/Header";
import Carousel from "../molecules/carousel/Carousel";

import slide_001 from "../../assets/images/blog/blog_001.webp";
import slide_002 from "../../assets/images/blog/blog_002.webp";
import slide_003 from "../../assets/images/blog/blog_003.webp";
import VideoCard from "../molecules/cards/CardVideo";
import robot from "../../assets/images/banner-hero/img_banner_hero_005.webp";
import SocialContacts from "../organisms/SocialContacts";

import blogData from "../../data/blogData.json"; // Importa datos del JSON

const Blog = () => {
    const navigate = useNavigate();

    const slides = [
        { image: slide_001, text: <span>Descubre herramientas para <span className="text-yellow-500">analizar</span> tu sitio web</span> },
        { image: slide_002, text: <span>Aprende sobre las <span className="text-yellow-500">tecnologías</span> usadas en desarrollo web</span> },
        { image: slide_003, text: <span>Herramientas de <span className="text-yellow-500">diseño y desarrollo</span> web para tus proyectos</span> },
    ];

    const handleCardClick = (id) => {
        navigate(`/blog/${id}`); // Navegar al artículo según el ID
    };

    return (
        <React.Fragment>
            <Header />
            <Carousel slides={slides} />
            <div className="w-full lg:w-10/12 m-auto">
                <div className="pt-40 pb-10 px-10">
                    <div className="w-full lg:w-8/12 xl:max-w-[1024px] lg:h-[250px] m-auto flex flex-col md:flex-row gap-4 p-6 lg:p-10 items-center bg-gradient-cards from-brand-gray via-brand-gray to-brand-dark-gray border border-yellow-500 rounded-xl">
                        <div className="relative w-full md:w-5/12 lg:w-1/2 h-[160px] lg:h-full">
                            <img className="absolute lg:left-1/2 lg:-translate-x-1/2 bottom-[-20px] lg:bottom-[-80px] w-[100%] md:w-[350px] lg:w-[500px] max-w-none m-auto" src={robot} />
                        </div>
                        <div className="text-center w-full md:w-7/12 lg:w-1/2">
                            <p className="text-yellow-500 font-bold text-4xl">
                                Aprende sobre diseño y desarrollo web
                            </p>
                            <p className="text-white font-normal text-xl">
                                Encuentra tutoriales para tus proyectos
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-full justify-center grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 p-10">
                    {blogData.map((article) => (
                        <VideoCard
                            key={article.id}
                            image={article.image}
                            title={article.title}
                            description={article.shortDescription}
                            date={article.date}
                            onActionClick={() => handleCardClick(article.id)}
                        />
                    ))}
                </div>
            </div>
            <SocialContacts />
        </React.Fragment>
    );
};

export default Blog;
