import React from "react";

const Card = ({ className, children, onClick }) => {
    return (
        <div
            className={`rounded-lg bg-gradient-cards from-brand-gray via-brand-gray to-brand-dark-gray ${className}`}
            onClick={onClick} // Maneja el evento de clic
            role="button" // Indica que es clicable
            tabIndex={0} // Hace el elemento accesible con teclado
            onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") onClick?.(); // Soporta clic por teclado
            }}
        >
            {children}
        </div>
    );
};

export default Card;
