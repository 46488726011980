import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './locales/en.json';
import translationES from './locales/es.json';

const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: {
      order: ['navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'],
    },
    fallbackLng: 'en',  // Asegúrate de que el idioma por defecto no esté sobrescribiendo la detección.
    interpolation: {
      escapeValue: false,
    },
    supportedLngs: ['en', 'es'],
    nonExplicitSupportedLngs: true,
  });

export default i18n;
