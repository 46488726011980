import UIButton from "../atoms/UIButton";
import CardService from "../molecules/cards/CardService";
import CardTest from "../molecules/cards/CardTest";
import { FormContact } from "../molecules/forms/FormContact";
import SliderItems from "../molecules/sliders/SliderItems";
import BannerHero from "../organisms/BannerHero";
import Header from "../organisms/Header";
import SocialContacts from "../organisms/SocialContacts";
import { useTranslation } from "react-i18next";


const Home =()=>{
    const { t } = useTranslation();



    return (
        <>
            <Header />
            <div className="flex justify-center">
                <BannerHero />                
            </div>
            <div className="w-10/12 xl:max-w-[1440px] m-auto flex flex-col-reverse md:flex-row justify-center py-20 ">
                <div className="w-full md:w-1/2">
                    <CardService />
                </div>
                <div className="w-full md:w-1/2 px-2 md:px-10">
                    <p className="text-white text-center pb-10">
                        {t('HomeContactTitle')}                        
                    </p>
                    <FormContact />
                </div>
            </div>
            <div id="aboutUs"></div>
            <div className="w-10/12 lg:w-6/12 xl:max-w-[1440px] m-auto flex flex-col items-center py-20 text-center gap-8">
                <p className="text-white font-normal text-base">
                    {t('HomeParagraphCompromise')}                    
                </p>
                <p className="text-white font-bold text-2xl">
                    {t('HomeTitleGrowth')}                    
                </p>
                <UIButton 
                    primary
                    children={<>{t('HomeButtonDownload')}</>}
                    url={'https://enlacewebdesign.com/portafolio/brochure_enlace-web-design-es-2024.pdf'}
                />
                <p className="text-white font-normal text-base">
                    {t('HomeParagraphResponsive')}                    
                </p>
            </div>
            <div className="w-10/12 xl:max-w-[1440px] m-auto flex flex-col items-center justify-center py-20 ">
                <p className="text-white font-bold text-2xl mb-20">
                    Tecnologías de última generación:
                </p>
                <SliderItems />
            </div>
            <div className="w-10/12 xl:max-w-[1440px] m-auto flex justify-center py-20 ">
                <CardTest />
            </div>
            <SocialContacts />
        </>
    )
}
export default Home;