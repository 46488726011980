import React from 'react';
import { useNavigate } from "react-router-dom";

import iconBeaker from "../../../assets/images/icons/icon_beaker.webp";
import iconCog from "../../../assets/images/icons/icon_cog.webp";
import iconGlobe from "../../../assets/images/icons/icon_globe_alt.webp";
import UIButton from '../../atoms/UIButton';
import robotDog from "../../../assets/images/cards/robot_dog.webp";
import robotSearch from "../../../assets/images/cards/robot_search.webp";
import Card from './Card';
import { useTranslation } from "react-i18next";

function CardTest() {
    const { t } = useTranslation();
    
    const navigate = useNavigate();
    const goBlog = () => {
        navigate("/blog");
    };

    return (
        <Card 
            className={'p-2 lg:p-28 w-full md:w-10/12 m-auto relative '}
            children={
                <div className="">
                    <p className="text-white text-left text-lg font-light">
                        {t('CardTestTitle')}                        
                    </p>
                    <p className="text-white text-left text-2xl font-bold">
                        {t('CardTestSubTitle')}
                    </p>
                    <div className="my-4  border border-yellow-500 rounded-lg">
                        <div className="p-1">
                            <div className="w-full flex flex-wrap justify-center p-2 rounded-lg">
                                <div className="w-full p-2 md:w-2/12 flex justify-center items-start">
                                    <img className='w-16 h-16' width={60} height={60} src={iconBeaker} alt='icon beacker' />
                                </div>
                                <div className="w-full md:w-8/12 text-left p-2">
                                    <h3 className="text-white font-bold">
                                        {t('TestResponsiveTitle')}
                                    </h3>
                                    <p className="text-white text-sm mb-0">
                                        {t('TestResponsiveContent')}                                        
                                    </p>
                                </div>
                                <div className="w-full md:w-1/12 flex justify-center items-center  md:border-l md:border-l-slate-500">
                                    <UIButton 
                                        arrowRightYellow
                                        children={' '}
                                    />
                                </div>
                            </div>
                        </div>          
                        <div>
                            <hr className=' border-[0.75px] border-gray-500 w-11/12 m-auto' />
                        </div>      
                        <div className="p-1 mt-4">
                            <div className="w-full flex flex-wrap justify-center p-2 rounded-lg ">
                                <div className="w-full p-2 md:w-2/12 flex justify-center items-start">
                                    <img className='w-16 h-16' width={60} height={60} src={iconCog} alt='icon cog' />
                                </div>
                                <div className="w-full md:w-8/12 text-left p-2">
                                    <h3 className="text-white font-bold">
                                        {t('TestLighthouseTitle')}                                        
                                    </h3>
                                    <p className="text-white text-sm mb-0">
                                        {t('TestLighthouseContent')}                                        
                                    </p>
                                </div>
                                <div className="w-full md:w-1/12 flex justify-center items-center md:border-l md:border-l-slate-500">
                                    <UIButton 
                                        arrowRightYellow
                                        children={' '}
                                    />
                                </div>
                            </div>
                        </div>    
                        <div>
                            <hr className=' border-[0.75px] border-gray-500 w-11/12 m-auto' />
                        </div>             
                        <div className="p-1 mt-4">
                            <div className="w-full flex flex-wrap justify-center p-2 rounded-lg">
                                <div className="w-full p-2 md:w-2/12 flex justify-center items-start">
                                    <img className='w-16 h-16' width={60} height={60} src={iconGlobe} alt='icon globe' />
                                </div>
                                <div className="w-full md:w-8/12 text-left p-2">
                                    <h3 className="text-white font-bold">
                                        {t('TestAnalythicsTitle')}                                        
                                    </h3>
                                    <p className="text-white text-sm mb-0">
                                        {t('TestAnalythicsContent')}                                        
                                    </p>
                                </div>
                                <div className="w-full md:w-1/12 flex justify-center items-center  md:border-l md:border-l-slate-500">
                                    <UIButton 
                                        arrowRightYellow
                                        children={' '}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center mt-3">
                        <div className="col-auto">
                        <UIButton
                            primary
                            scrollToTop
                            action={goBlog} // Navegar a /blog
                            children={<> {t('CardTestButton')} </>}
                        />
                        </div>
                    </div>
                    <div className='hidden md:block absolute left-[-100px] top-0'>
                        <img className='w-[200px]' src={robotSearch} alt='robot search'></img>
                    </div>
                    <div className='hidden md:block absolute right-[-100px] bottom-0'>
                        <img className='w-[200px]' src={robotDog} alt='robot dog'></img>
                    </div>
                </div>
            }
        />
    );
}

export default CardTest;
