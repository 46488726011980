
import iconBeaker from "../../../assets/images/icons/icon_beaker.webp";
import iconCog from "../../../assets/images/icons/icon_cog.webp";
import UIButton from "../../atoms/UIButton";
import { useTranslation } from "react-i18next";

function CardService() {
    const { t } = useTranslation();
    return (
        <div className=' text-white rounded-lg bg-gradient-cards from-brand-gray via-brand-gray to-brand-dark-gray p-6'>
            <p  className='text-white text-lg text-left font-bold mb-4'>
                {t('CardServiceTitle')}                
            </p>
            <p className='text-white text-lg text-left font-light mb-4'>
                {t('CardServiceSubTitle')}                                
            </p>
            <a href='/web-design' className="text-decoration-none" target='_self' rel="noopener noreferrer">
                <div className=' p-4 border border-yellow-500 rounded-lg flex items-start mb-4  hover:bg-[#ffffff20] transition-all duration-300 ease-in-out bg-transparent cursor-pointer'>
                    <div className='flex flex-col md:flex-row w-full justify-around items-start'>
                        <div className='flex justify-center md:w-1/6'>
                            <img width={60} height={60} src={iconBeaker} alt='icon beaker' />
                        </div>
                        <div className='text-left md:w-4/6 mt-4 md:mt-0'>
                            <h3 className='txtWhite text-lg font-bold'>
                                {t('ServiceTitleWeb')}
                            </h3>
                            <p className='txtWhite mb-0'>
                                {t('ServiceSubTitleWeb')} 
                                
                            </p>
                        </div>
                        <div className='flex min-h-[70px] justify-end items-center  w-full md:w-1/6 mt-4 md:mt-0'>
                            <UIButton 
                                arrowRightYellow
                                children={' '}
                            />
                        </div>
                    </div>
                </div>
            </a>
            <a href='https://enlacewebdesign.com/ecommerce/' className="text-decoration-none" target='_blank' rel="noopener noreferrer">
                <div className=' p-4 border border-yellow-500 rounded-lg flex items-start  hover:bg-[#ffffff20] transition-all duration-300 ease-in-out bg-transparent cursor-pointer'>
                    <div className='flex flex-col md:flex-row w-full justify-around items-start'>
                        <div className='flex justify-center md:w-1/6'>
                            <img width={60} height={60} src={iconCog} alt='icon cog' />
                        </div>
                        <div className='text-left md:w-4/6 mt-4 md:mt-0'>
                            <h3 className='txtWhite text-lg font-bold'>
                                {t('ServiceTitleEcommerce')}
                            </h3>
                            <p className='txtWhite mb-0'>
                                {t('ServiceSubTitleEcommerce')}                                
                            </p>
                        </div>
                        <div className='flex min-h-[70px] justify-end items-center w-full md:w-1/6 mt-4 md:mt-0'>
                            <UIButton 
                                arrowRightYellow
                                children={' '}
                            />
                        </div>
                    </div>
                </div>
            </a>
        </div>
    );
}

export default CardService;
