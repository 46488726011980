import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { useTranslation } from "react-i18next";
import UIButton from '../../../components/atoms/UIButton';
import cohete from "../../../assets/images/robots/cohete.webp";

export const FormContact = () => {
    const { t } = useTranslation();
    const form = useRef();
    const [formState, setFormState] = useState({
        user_name: '',
        user_email: '',
        message: ''
    });
    const [errors, setErrors] = useState({});
    const [formStart, setShowFormStart] = useState(true);
    const [formSend, setShowFormSend] = useState(false);

    const validateForm = () => {
        const newErrors = {};
        if (!formState.user_name) newErrors.user_name = 'Please enter your name.';
        if (!formState.user_email) newErrors.user_email = 'Please enter your email.';
        else if (!/\S+@\S+\.\S+/.test(formState.user_email)) newErrors.user_email = 'Email address is invalid.';
        if (!formState.message) newErrors.message = 'Please enter your message.';
        return newErrors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const sendEmail = (e) => {
        e.preventDefault();
        const newErrors = validateForm();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        setErrors({});
        emailjs.sendForm('service_vbre7bb', 'template_enlacecontacto', form.current, 'sGQCGwP9_ykaw0YIe')
            .then((result) => {
                console.log(result.text);
                setShowFormStart(false);
                setShowFormSend(true);
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <>
            {formStart && (
                <form ref={form} onSubmit={sendEmail} className="flex flex-col gap-2 needs-validation">
                    <div className='flex flex-col lg:flex-row justify-center gap-2 lg:gap-6'>
                        <div className='w-full lg:w-1/2'>
                            <label htmlFor="validationCustom01" className="block text-sm font-medium text-white">
                                {t('FormContactName')} 
                            </label>
                            <input
                                id="validationCustom01"
                                required
                                type="text"
                                name="user_name"
                                value={formState.user_name}
                                onChange={handleChange}
                                className={`p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.user_name ? 'border-red-500' : ''}`}
                                placeholder={t('FormContactNamePlaceholder')}
                            />
                            {errors.user_name && <p className="text-red-500 text-sm">{errors.user_name}</p>}
                        </div>
                        <div className='w-full lg:w-1/2'>
                            <label htmlFor="validationCustom03" className="block text-sm font-medium text-white">
                                {t('FormContactEmail')}                                 
                            </label>
                            <input
                                id="validationCustom03"
                                required
                                type="email"
                                name="user_email"
                                value={formState.user_email}
                                onChange={handleChange}
                                className={`p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.user_email ? 'border-red-500' : ''}`}
                                placeholder={t('FormContactEmailPlaceholder')} 
                            />
                            {errors.user_email && <p className="text-red-500 text-sm">{errors.user_email}</p>}
                        </div>
                    </div>
                    <div className='w-full'>
                        <label htmlFor="validationCustom04" className="block text-sm font-medium text-white">
                            {t('FormContactMessageLabel')}                            
                        </label>
                        <textarea
                            id="validationCustom04"
                            required
                            name="message"
                            value={formState.message}
                            onChange={handleChange}
                            className={`p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.message ? 'border-red-500' : ''}`}
                            placeholder={t('FormContactMessagePlaceholder')} 
                        />
                        {errors.message && <p className="text-red-500 text-sm">{errors.message}</p>}
                    </div>
                    <div className='w-full text-center my-4'>
                        <UIButton
                            type="submit" 
                            value="Send"
                            primary
                            children={<p>{t('FormContactButton')}</p>}
                        />
                    </div>
                </form>
            )}
            {formSend && (
                <div className='flex flex-col md:flex-row items-center mt-6 text-center'>
                    <div className='w-full md:w-1/2'>
                        <img src={cohete}></img>
                    </div>
                    <div className='w-full md:w-1/2'>
                        <div>
                            <p className='text-2xl text-white font-bold'>
                                {t('FormSendTitle')}
                            </p>
                            <p className='text-lg text-white'>
                                {t('FormSendText')}
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
