import React from "react";

const VideoCard = ({ image, title, description, date, onActionClick }) => {

  const handleCardClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll al top
    onActionClick(); // Ejecuta la acción de navegación
  };

  return (
    <div className="flex flex-col cursor-pointer" onClick={handleCardClick}>
      <div className="rounded-xl">
        <img className="w-full" src={image} alt={title} />
      </div>
      <div className="flex-col bg-gradient-cards border border-yellow-500 from-brand-gray via-brand-gray to-brand-dark-gray rounded-b-xl rounded-t-none p-5">
        <div>
          <p className="text-yellow-500 text-xl">{title}</p>
          <p className="text-white text-base">{description}</p>
          <p className="text-white text-base">{date}</p>
        </div>
      </div>
    </div>
  );
};

export default VideoCard;