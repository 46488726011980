import React from "react";
import Header from "../organisms/Header";
import imgBuild from "../../assets/images/social/build_react.webp";
import CardSocial from "../molecules/cards/CardSocial";
import { FormContact } from "../molecules/forms/FormContact";

const Contact = () => {
    return (
        <React.Fragment>
            <Header />
            <div className="w-screen min-h-[800px] lg:min-h-[1000px] flex flex-col items-center justify-center md:flex-row bg-social-contacts bg-contain bg-bottom bg-no-repeat py-6">
                <div className="w-10/12 lg:w-9/12 max-w-[1300px] m-auto flex flex-col-reverse xl:justify-center md:flex-row relative">
                    {/* Contenedor de la imagen */}
                    <div className="w-full md:w-6/12 lg:w-7/12 xl:max-w-[700px] h-[550px] sm:min-h-[800px] md:h-[780px] lg:h-[800px] flex m-auto relative">
                        <img
                            className="absolute bottom-[-20px] lg:bottom-[-80px] translate-x-[-50%] left-[50%] w-full h-auto object-contain"
                            src={imgBuild}
                            alt="Build React"
                        />
                    </div>
                    {/* Contenedor del texto */}
                    <div className="w-10/12 md:w-6/12 lg:w-5/12 xl:max-w-[700px] m-auto xl:m-0 flex flex-col justify-center items-center">
                        <div className="mb-8 text-center pt-20 lg:pt-2">
                            <p className="text-white text-base lg:text-2xl font-bold mb-10">
                                Contáctanos en nuestra línea de atención:
                            </p>
                            <a
                                className="text-white text-base lg:text-xl font-normal"
                                href="mailto: contacto@enlacewebdesign.com"
                            >
                                contacto@enlacewebdesign.com
                            </a>
                            <div className="py-5">
                                <FormContact />
                            </div>
                        </div>
                        <div className="w-full xl:w-10/12 flex justify-center items-center">
                            <CardSocial />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Contact;
