import React from 'react';
import youtube from "../../../assets/images/social/youtube.webp";
import twitter from "../../../assets/images/social/twitter.webp";
import linkedin from "../../../assets/images/social/linkedin.webp";
import Card from './Card';

function CardSocial() {
    return (
        <Card
            className={'w-full flex items-center'}
            children={
                <div className="w-full text-white p-6">
                    <div className="flex items-center mb-4">
                        <div className="w-1/6">
                            <img width="30" height="30" alt="youtube" className="imgRedes" src={youtube} />
                        </div>
                        <div className="w-5/6">
                            <p className="txtRedes">
                                @enlacewebdesign
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center mb-4">
                        <div className="w-1/6">
                            <img width="30" height="30" alt="twitter" className="imgRedes" src={twitter} />
                        </div>
                        <div className="w-5/6">
                            <p className="txtRedes">
                                @EnlaceWebcol
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center mb-4">
                        <div className="w-1/6">
                            <img width="30" height="30" alt="linkedin" className="imgRedes" src={linkedin} />
                        </div>
                        <div className="w-5/6">
                            <p className="txtRedes">
                                @enlace-web-design-col
                            </p>
                        </div>
                    </div>
                </div>
            }
        />
    );
}

export default CardSocial;
