import React from "react";

const Brand = ({ src, className, action }) => {
    return (
        <img
            onClick={action} 
            className={`cursor-pointer ${className}`}
            src={src}
            alt="Brand"
        />
    );
};

export default Brand;
