import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";
import UIButton from "../../../components/atoms/UIButton";
import cohete from "../../../assets/images/robots/cohete.webp";
import robot_008 from "../../../assets/images/robots/robot_002.webp";

const ContactAndCartModal = ({ plan }) => {
    const { t } = useTranslation();
    const form = useRef();
    const [formState, setFormState] = useState({
        user_name: "",
        user_email: "",
        message: "",
    });
    const [errors, setErrors] = useState({});
    const [formStart, setShowFormStart] = useState(true);
    const [formSend, setShowFormSend] = useState(false);

    const [selectedExtras, setSelectedExtras] = useState([]);
    const [totalPrice, setTotalPrice] = useState(plan ? plan.price : 0);

    const validateForm = () => {
        const newErrors = {};
        if (!formState.user_name) newErrors.user_name = "Por favor ingrese su nombre.";
        if (!formState.user_email) newErrors.user_email = "Por favor ingrese su correo electrónico.";
        else if (!/\S+@\S+\.\S+/.test(formState.user_email)) newErrors.user_email = "Correo electrónico inválido.";
        if (!formState.message) newErrors.message = "Por favor ingrese un mensaje.";
        return newErrors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const sendEmail = (e) => {
        e.preventDefault();
        const newErrors = validateForm();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
    
        // Crear una cadena con los nombres de los extras seleccionados
        const extrasList = selectedExtras.map((extra) => extra.name).join(", ");
    
        // Preparar los datos del correo
        const emailData = {
            user_name: formState.user_name,
            user_email: formState.user_email,
            message: formState.message,
            plan_name: plan.name, // Nombre del plan seleccionado
            extras: extrasList || "No extras selected", // Extras seleccionados o texto por defecto
            total_cost: totalPrice.toLocaleString("es-CO"), // Precio total con formato
        };
    
        setErrors({});
        emailjs
            .send("service_vbre7bb", "template_enlacecontacto", emailData, "sGQCGwP9_ykaw0YIe")
            .then(
                (result) => {
                    console.log("Email enviado:", result.text);
                    setShowFormStart(false);
                    setShowFormSend(true);
                },
                (error) => {
                    console.error("Error al enviar el email:", error.text);
                    alert("Hubo un problema al enviar el formulario. Por favor, inténtalo de nuevo.");
                }
            );
    };
    

    const handleExtraChange = (extra) => {
        const isSelected = selectedExtras.find((item) => item.id === extra.id);
    
        if (isSelected) {
            // Si ya está seleccionado, lo removemos
            setSelectedExtras(selectedExtras.filter((item) => item.id !== extra.id));
            setTotalPrice((prevTotal) => prevTotal - extra.price);
        } else {
            // Si no está seleccionado, verificamos si es "logo_design" o "branding_manual"
            let updatedExtras = [...selectedExtras, extra];
    
            if (extra.id === "logo_design") {
                const manualImage = selectedExtras.find((item) => item.id === "branding_manual");
                if (manualImage) {
                    // Removemos "branding_manual" y ajustamos el precio
                    updatedExtras = updatedExtras.filter((item) => item.id !== "branding_manual");
                    setTotalPrice((prevTotal) => prevTotal - manualImage.price);
                }
            } else if (extra.id === "branding_manual") {
                const logoDesign = selectedExtras.find((item) => item.id === "logo_design");
                if (logoDesign) {
                    // Removemos "logo_design" y ajustamos el precio
                    updatedExtras = updatedExtras.filter((item) => item.id !== "logo_design");
                    setTotalPrice((prevTotal) => prevTotal - logoDesign.price);
                }
            }
    
            setSelectedExtras(updatedExtras);
            setTotalPrice((prevTotal) => prevTotal + extra.price);
        }
    };
    

    return (
        <div >
            {plan && (
                <div>
                    {/* Descripción */}
                    <div className="w-full m-auto">
                        <div className="pb-10">
                            <div className="w-full lg:w-8/12 xl:max-w-[1024px] m-auto flex flex-col sm:flex-row gap-4 p-5 items-center bg-gray-800  rounded-xl">
                                <div className="relative w-full sm:w-4/12 md:w-5/12 lg:w-4/12 h-[80px] lg:h-1">
                                    <img className="absolute lg:left-1/2 lg:-translate-x-1/2 bottom-[-10px] lg:bottom-[-80px] w-[150px] md:w-[190px] lg:w-[200px] max-w-none m-auto" src={robot_008} />
                                </div>
                                <div className="text-left w-full sm:w-8/12  md:w-7/12 lg:w-8/12">
                                    <p className="text-yellow-500 font-bold text-base leading-tight">
                                        Qué obtienes con este plan:
                                    </p>
                                    <p className="text-white font-normal text-sm leading-tight">                                        
                                        {plan.descriptionPlan}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex flex-col lg:flex-row gap-10">
                        {/* Cotización */}
                        <div className="w-full lg:w-1/2">
                            <h3 className="text-3xl font-bold text-yellow-500">{plan.name}</h3>
                            <p className="text-xl font-semibold text-gray-300 mt-2">Precio base: ${plan.price.toLocaleString("es-CO")} COP</p>
                            {plan.extras && plan.extras.length > 0 ? (
                                <>
                                    <h4 className="mt-6 text-2xl font-semibold text-yellow-400">Puedes añadir:</h4>
                                    {plan.extras.map((extra) => (
                                        <div key={extra.id} className="flex items-center justify-between py-2 border-b border-gray-700 gap-4">
                                            <label className="text-gray-300">{extra.name}</label>
                                            <div className="flex items-center">
                                                <p className="text-gray-400 text-sm mr-3">${extra.price.toLocaleString("es-CO")}</p>
                                                <input
                                                    type="checkbox"
                                                    className="form-checkbox"
                                                    onChange={() => handleExtraChange(extra)}
                                                    checked={selectedExtras.some((item) => item.id === extra.id)}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <p className="text-gray-400 mt-4">No hay extras disponibles para este plan.</p>
                            )}

                            <p className="text-2xl font-bold text-yellow-400 mt-4 text-right">Total: ${totalPrice.toLocaleString("es-CO")} COP</p>
                        </div>
                        {/* Formulario */}
                        <div className="w-full lg:w-1/2">
                            {formStart && (
                                <form ref={form} onSubmit={sendEmail} className="flex flex-col gap-4">
                                    <div>
                                        <label htmlFor="user_name" className="block text-sm font-medium text-gray-300">
                                            {t("FormContactName")}
                                        </label>
                                        <input
                                            id="user_name"
                                            name="user_name"
                                            type="text"
                                            value={formState.user_name}
                                            onChange={handleChange}
                                            className={`p-2 mt-1 block w-full rounded-md bg-gray-800 text-white border-gray-600 ${
                                                errors.user_name ? "border-red-500" : ""
                                            }`}
                                            placeholder="Ingrese su nombre"
                                        />
                                        {errors.user_name && <p className="text-red-500 text-sm">{errors.user_name}</p>}
                                    </div>
                                    <div>
                                        <label htmlFor="user_email" className="block text-sm font-medium text-gray-300">
                                            {t("FormContactEmail")}
                                        </label>
                                        <input
                                            id="user_email"
                                            name="user_email"
                                            type="email"
                                            value={formState.user_email}
                                            onChange={handleChange}
                                            className={`p-2 mt-1 block w-full rounded-md bg-gray-800 text-white border-gray-600 ${
                                                errors.user_email ? "border-red-500" : ""
                                            }`}
                                            placeholder="Ingrese su correo electrónico"
                                        />
                                        {errors.user_email && <p className="text-red-500 text-sm">{errors.user_email}</p>}
                                    </div>
                                    <div>
                                        <label htmlFor="message" className="block text-sm font-medium text-gray-300">
                                            {t("FormContactMessageLabel")}
                                        </label>
                                        <textarea
                                            id="message"
                                            name="message"
                                            value={formState.message}
                                            onChange={handleChange}
                                            className={`p-2 mt-1 block w-full rounded-md bg-gray-800 text-white border-gray-600 ${
                                                errors.message ? "border-red-500" : ""
                                            }`}
                                            placeholder="Ingrese su mensaje"
                                        />
                                        {errors.message && <p className="text-red-500 text-sm">{errors.message}</p>}
                                    </div>
                                    <div className="text-center">
                                        <UIButton 
                                            type="submit" 
                                            primary 
                                            children={<p>{t("FormContactButton")}</p>}
                                        />
                                    </div>
                                </form>
                            )}
                            {formSend && (
                                <div className="flex flex-col items-center mt-6 text-center">
                                    <img src={cohete} alt="Enviado" className="w-[40%] mb-4" />
                                    <p className="text-2xl text-yellow-500 font-bold">{t("FormSendTitle")}</p>
                                    <p className="text-lg text-gray-300">{t("FormSendText")}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ContactAndCartModal;
