import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import blogData from "../../data/blogData.json"; // Importa el JSON
import robot from "../../assets/images/robots/robot_010.png";
import Header from "../organisms/Header";
import SocialContacts from "../organisms/SocialContacts";
import UIButton from "../atoms/UIButton";
import iconSave from "../../assets/images/icons/icon_download.webp";
import youtube from "../../assets/images/social/youtube.webp";
import twitter from "../../assets/images/social/twitter.webp";
import whatsapp from "../../assets/images/social/whatsapp.webp";
import linkedin from "../../assets/images/social/linkedin.webp";

const BlogArticle = () => {
    const { id } = useParams(); // Obtén el ID de la URL
    const navigate = useNavigate();

    // Estado para el buscador
    const [searchQuery, setSearchQuery] = useState("");

    // Encuentra el artículo en el JSON por su ID
    const articleData = blogData.find((article) => article.id === parseInt(id));

    // Redirige si no se encuentra el artículo
    if (!articleData) {
        navigate("/blog");
        return null;
    }

    const goMenuBlog = () => {
        navigate("/blog");
    };

    // Desestructuración de datos del artículo
    const { title, date, lead, fullDescription, image, assets } = articleData;

    return (
        <React.Fragment>
            <Header />
            <div className="w-full">
                <div className="w-full min-h-[800px] m-auto py-10 lg:py-24">
                    <div className="pt-40 pb-20 px-10">
                        <div className="relative w-full xl:w-8/12 lg:max-w-[850px] lg:h-[200px] m-auto flex flex-col-reverse md:flex-row gap-4 p-6 lg:p-10 items-center bg-gradient-cards from-brand-gray via-brand-gray to-brand-dark-gray border border-yellow-500 rounded-xl">
                            <div className="absolute -top-8 left-5 z-40">
                                <UIButton
                                    scrollToTop
                                    className={'text-yellow-500'}
                                    children={'< Volver al menú'}
                                    action={goMenuBlog}
                                />
                            </div>
                            <div className="text-center w-full md:w-7/12 lg:w-9/12">
                                <p className="text-white font-normal text-xl">
                                    Blog de tecnología
                                </p>
                                <p className="text-yellow-500 font-bold text-4xl">
                                    Manténte informado
                                </p>
                                <p className="text-white font-normal text-xl">
                                    temas de tecnología, desarrollo web y aplicaciones
                                </p>
                            </div>
                            <div className="relative w-full md:w-5/12 lg:w-3/12 sm:h-[130px] lg:h-[160px] z-20">
                                <img
                                    className="absolute -right-10 lg:left-1/2 lg:-translate-x-1/2 bottom-[-20px] lg:bottom-[-100px] w-[140px] sm:w-[350px] md:w-[350px] lg:w-[400px] max-w-none m-auto"
                                    src={robot}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex flex-col lg:flex-row gap-5 px-5">
                        <div className="w-full lg:w-10/12 p-5 lg:p-20">
                            <h1 className="text-yellow-500 mb-5 font-bold text-4xl text-center">{title}</h1>
                            <p className="text-gray-300 text-sm mb-4">Publicado el: {date}</p>
                            {/* Nuevo párrafo "lead" */}
                            <p className="text-gray-300 text-base italic mb-6">{lead}</p>
                            <div className="px-5">
                                <img className="w-full mb-6 rounded-lg" src={image} alt={title} />
                            </div>
                            <p className="text-white text-lg">{fullDescription}</p>
                        </div>
                        <div className="w-full lg:w-2/12 bg-gradient-cards from-brand-gray via-brand-gray to-brand-dark-gray p-5 border border-yellow-500 rounded-xl">
                            {/* Buscador */}
                            <div className="mb-5">
                                <input
                                    type="text"
                                    placeholder="Buscar artículos..."
                                    className="w-full p-2 rounded bg-gray-800 text-white border border-gray-600"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </div>

                            {/* Lista de artículos relacionados */}
                            <div className="mb-5">
                                <h3 className="text-yellow-500 font-bold text-lg mb-3">Artículos relacionados</h3>
                                <ul className="space-y-3">
                                    {blogData
                                        .filter((article) =>
                                            article.title.toLowerCase().includes(searchQuery.toLowerCase()) &&
                                            article.id !== parseInt(id)
                                        )
                                        .map((article) => (
                                            <li key={article.id}>
                                                <button
                                                    onClick={() => navigate(`/blog/${article.id}`)}
                                                    className="w-full text-white hover:underline text-sm border-b border-b-slate-600 p-4 hover:bg-slate-500"
                                                >
                                                    {article.title}
                                                </button>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                            

                            {/* Listado de assets descargables */}
                            {assets && assets.length > 0 && (
                                <div className="mt-10">
                                    <h3 className="text-yellow-500 font-bold text-lg mb-3">Assets descargables:</h3>
                                    <ul className="space-y-3">
                                        {assets.map((asset, index) => (
                                            <li key={index}>
                                                
                                                    <a
                                                        href={asset.url}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="text-white hover:underline text-sm"
                                                    >
                                                        <div className="flex gap-4 items-center border-b border-b-slate-600 p-4 hover:bg-slate-500">
                                                            <img className="w-6 h-6" src={iconSave}></img>
                                                            {asset.name}
                                                        </div>
                                                    </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            {/* Vínculos a redes sociales */}
                            <div className="w-full flex flex-col items-center mb-5 mt-10">
                                <p className="text-yellow-500 font-bold text-lg mb-3">Síguenos en:</p>
                                <div className="flex justify-center w-full space-x-3">
                                    <UIButton
                                        url={'#'}
                                        children={
                                            <>
                                                <img className="w-6 h-6" src={youtube}></img>
                                            </>
                                        }
                                    />
                                    <UIButton
                                        url={'#'}
                                        children={
                                            <>
                                                <img className="w-6 h-6" src={twitter}></img>
                                            </>
                                        }
                                    />
                                    <UIButton
                                        url={'#'}
                                        children={
                                            <>
                                                <img className="w-6 h-6" src={whatsapp}></img>
                                            </>
                                        }
                                    />
                                    <UIButton
                                        url={'#'}
                                        children={
                                            <>
                                                <img className="w-6 h-6" src={linkedin}></img>
                                            </>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SocialContacts />
        </React.Fragment>
    );
};

export default BlogArticle;
