import React, { useState, useEffect } from "react";

const Carousel = ({ slides, autoPlay = true, autoPlayInterval = 8000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  // Agregar funcionalidad para avance automático
  useEffect(() => {
    if (!autoPlay) return; // Si autoPlay está deshabilitado, no inicia el intervalo

    const interval = setInterval(() => {
      nextSlide();
    }, autoPlayInterval);

    return () => clearInterval(interval); // Limpiar el intervalo al desmontar
  }, [currentIndex, autoPlay, autoPlayInterval]);

  return (
    <div className="relative w-full overflow-hidden">
      {/* Slider */}
      <div
        className="flex transition-transform duration-500 ease-in-out"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {slides.map((slide, index) => (
          <div
            key={index}
            className="w-full flex-shrink-0 relative flex justify-center items-center"
          >
            <img
              src={slide.image}
              alt={slide.text}
              className="w-full h-96 object-cover md:h-80 lg:h-[700px]"
            />
            <div className="absolute inset-0 bg-black bg-opacity-80 lg:bg-opacity-40 flex justify-center items-center">
              <div className="w-9/12">
                <p className="w-full lg:w-[50%] text-white text-[24px] leading-[26px] md:text-[40px] md:leading-[44px] lg:text-[60px] lg:leading-[64px] text-center lg:text-left font-semibold px-5 lg:px-10 ">
                  {slide.text}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Controles */}
      <button
        onClick={prevSlide}
        className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full hover:bg-gray-600 focus:outline-none"
      >
        &#10094;
      </button>
      <button
        onClick={nextSlide}
        className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full hover:bg-gray-600 focus:outline-none"
      >
        &#10095;
      </button>

      {/* Indicadores */}
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-4">
        {slides.map((_, index) => (
          <div
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`w-10 h-10 md:w-8 md:h-8 rounded-full ${
              currentIndex === index ? "bg-yellow-500" : "bg-gray-400"
            } cursor-pointer`}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
